import { gql } from '@apollo/client';

export const LIST = gql`
  mutation ($input: InstrumentMasterInput!) {
    instrumentMasters(input: $input) {
      paginatorInfo {
        count
      }
      success
      message
      data {
        _id
        lab
        department
        instType
        instId
        ipAddress
        portNo
        enteredBy
        status
        companyCode
        environment
        dateOfEntry
        lastUpdated
      }
    }
  }
`;

export const CREATE = gql`
  mutation ($input: CreateInstrumentMasterInput!) {
    createInstrumentMaster(input: $input) {
      success
      message
    }
  }
`;

export const REMOVE = gql`
  mutation ($input: InstrumentmasterRemoveInput!) {
    removeInstrumentMaster(input: $input) {
      success
      message
    }
  }
`;

export const UPDATE = gql`
  mutation ($input: UpdateInstrumentMasterInput!) {
    updateInstrumentMaster(input: $input) {
      success
      message
    }
  }
`;

export const FILTER = gql`
  mutation ($input: InstrumentMasterInput!) {
    filterInstrumentMaster(input: $input) {
      paginatorInfo {
        count
      }
      success
      message
      data {
        _id
        lab
        department
        instType
        instId
        ipAddress
        portNo
        enteredBy
        status
        companyCode
        environment
        dateOfEntry
        lastUpdated
      }
    }
  }
`;
