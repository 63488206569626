/**
 * @fileoverview Use this file invoke LimsPlus API
 * implementation related to LimsPlus standards
 
 * @author limsplus
 */
import { client, ServiceResponse } from '@/core-services/graphql/apollo-client';
import { stores } from '@/stores';
import { LIST, CREATE, REMOVE, UPDATE, FILTER } from './mutation';

export class InstrumentMasterService {
  list = (page = 0, limit = 10) =>
    new Promise<any>((resolve, reject) => {
      client
        .mutate({
          mutation: LIST,
          variables: { input: { page, limit } },
        })
        .then((response: any) => {
          stores.instrumentMasterStore.updateInstrumentMasterList(
            response.data,
          );
          resolve(response.data);
        })
        .catch((error) =>
          reject(new ServiceResponse<any>(0, error.message, undefined)),
        );
    });

  create = (variables: any) =>
    new Promise<any>((resolve, reject) => {
      client
        .mutate({
          mutation: CREATE,
          variables,
        })
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((error) =>
          reject(new ServiceResponse<any>(0, error.message, undefined)),
        );
    });

  delete = (variables: any) =>
    new Promise<any>((resolve, reject) => {
      client
        .mutate({
          mutation: REMOVE,
          variables,
        })
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((error) =>
          reject(new ServiceResponse<any>(0, error.message, undefined)),
        );
    });

  update = (variables: any) =>
    new Promise<any>((resolve, reject) => {
      client
        .mutate({
          mutation: UPDATE,
          variables,
        })
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((error) =>
          reject(new ServiceResponse<any>(0, error.message, undefined)),
        );
    });

  filter = (variables: any) =>
    new Promise<any>((resolve, reject) => {
      stores.uploadLoadingFlag(false);
      client
        .mutate({
          mutation: FILTER,
          variables,
        })
        .then((response: any) => {
          if (!response.data.filterInstrumentMaster.success) return this.list();
          stores.instrumentMasterStore.updateInstrumentMasterList({
            instrumentMasters: {
              success: response.data.filterInstrumentMaster.success,
              data: response.data.filterInstrumentMaster.data,
              paginatorInfo: {
                count: response.data.filterInstrumentMaster.paginatorInfo.count,
              },
            },
          });
          stores.uploadLoadingFlag(true);
          resolve(response.data);
        })
        .catch((error) =>
          reject(new ServiceResponse<any>(0, error.message, undefined)),
        );
    });

  //   filterByFields = (variables: any) =>
  //     new Promise<any>((resolve, reject) => {
  //       stores.uploadLoadingFlag(false);
  //       client
  //         .mutate({
  //           mutation: FILTER_BY_FIELDS,
  //           variables,
  //         })
  //         .then((response: any) => {
  //           if (!response.data.filterByFieldsInterfaceManagers.success)
  //             return this.listInterfaceManager();
  //           stores.interfaceManagerStore.filterInterfaceManager({
  //             filterInterfaceManagers: {
  //               data: response.data.filterByFieldsInterfaceManagers.data,
  //               paginatorInfo: {
  //                 count:
  //                   response.data.filterByFieldsInterfaceManagers.paginatorInfo
  //                     .count,
  //               },
  //             },
  //           });
  //           stores.uploadLoadingFlag(true);
  //           resolve(response.data);
  //         })
  //         .catch((error) =>
  //           reject(new ServiceResponse<any>(0, error.message, undefined)),
  //         );
  //     });

  //   findByFields = (variables: any) =>
  //     new Promise<any>((resolve, reject) => {
  //       client
  //         .mutate({
  //           mutation: FIND_BY_FIELDS,
  //           variables,
  //         })
  //         .then((response: any) => {
  //           resolve(response.data);
  //         })
  //         .catch((error) =>
  //           reject(new ServiceResponse<any>(0, error.message, undefined)),
  //         );
  //     });
}
