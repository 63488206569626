import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import {
  Toast,
  Buttons,
  Grid,
  List,
  Form,
  Svg,
  ModalConfirm,
  MainPageHeading,
  ManualImportTabs,
  StaticInputTable,
  ImportFile,
} from '@/library/components';
import { DataConversationList } from '../components';
import { useForm, Controller } from 'react-hook-form';
import { DataConversationHoc } from '../hoc';
import { useStores } from '@/stores';
import { RouterFlow } from '@/flows';
import { toJS } from 'mobx';
import * as XLSX from 'xlsx';
import { resetDataConversation } from '../startup';
import { lookupItems, lookupValue } from '@/library/utils';

const DataConversation = DataConversationHoc(
  observer(() => {
    const { loginStore, dataConversationStore, routerStore } = useStores();
    const {
      control,
      handleSubmit,
      formState: { errors },
      setValue,
      reset,
    } = useForm();
    useEffect(() => {
      // Default value initialization
      setValue('status', dataConversationStore.dataConversation?.status);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataConversationStore.dataConversation]);
    const [modalConfirm, setModalConfirm] = useState<any>();
    const [hideAddDataConversation, setHideAddDataConversation] =
      useState<boolean>(true);
    const [isImport, setIsImport] = useState<boolean>(false);
    const [arrImportRecords, setArrImportRecords] = useState<Array<any>>([]);

    const onSubmitDataConversation = () => {
      dataConversationStore.dataConversationService
        .addDataConversation({
          input: isImport
            ? { isImport, arrImportRecords }
            : { isImport, ...dataConversationStore.dataConversation },
        })
        .then((res) => {
          if (res.createDataConversation.success) {
            Toast.success({
              message: `😊 ${res.createDataConversation.message}`,
            });
            setHideAddDataConversation(true);
            reset();
            resetDataConversation();
            dataConversationStore.dataConversationService.listDataConversation();
            setIsImport(false);
            setArrImportRecords([]);
          }
        });
    };

    const handleFileUpload = (file: any) => {
      const reader = new FileReader();
      reader.addEventListener('load', (evt: any) => {
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, { raw: true });
        const list = data.map((item: any) => {
          return {
            hexadecimal: item['Hexa Decimal'],
            binary: item?.Binary,
            ascii: item?.ASCII,
            environment: loginStore.login?.environment,
            companyCode: loginStore.login?.companyCode,
            enteredBy: loginStore.login?.userId,
            status: 'D',
          };
        });
        // Define the fields that should not be missing or empty
        const requiredFields = ['hexadecimal', 'binary', 'ascii'];
        // Filter out records with missing or empty required fields using lodash
        const filteredRecords = _.filter(list, (record) =>
          _.every(requiredFields, (field) => !_.isEmpty(record[field])),
        );
        setArrImportRecords(filteredRecords);
      });
      reader.readAsBinaryString(file);
    };

    return (
      <>
        <MainPageHeading
          title={routerStore.selectedComponents?.title || ''}
          store={loginStore}
        />
        <div
          className='flex justify-end'
          style={{
            position: 'fixed',
            right: '30px',
            top: '135px',
            zIndex: 9999,
          }}
        >
          {RouterFlow.checkPermission(routerStore.userPermission, 'Add') && (
            <Buttons.ButtonCircleAddRemoveBottom
              show={hideAddDataConversation}
              onClick={() =>
                setHideAddDataConversation(!hideAddDataConversation)
              }
            />
          )}
        </div>
        <div className=' mx-auto  flex-wrap'>
          <div
            className={
              'p-2 rounded-lg shadow-xl ' +
              (hideAddDataConversation ? 'hidden' : 'shown')
            }
          >
            <ManualImportTabs
              isImport={isImport}
              isImportDisable={
                !RouterFlow.checkPermission(
                  toJS(routerStore.userPermission),
                  'Import',
                )
              }
              onClick={(flag) => {
                setIsImport(flag);
              }}
            />
            {!isImport ? (
              <Grid cols={2}>
                <List direction='col' space={4} justify='stretch' fill>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Form.Input
                        type='text'
                        label='Hexa Decimal'
                        id='hexadecimal'
                        name='hexadecimal'
                        placeholder={
                          errors.hexadecimal
                            ? 'Please Enter hexadecimal'
                            : 'Hexa Decimal'
                        }
                        hasError={!!errors.hexadecimal}
                        value={value}
                        onChange={(hexadecimal) => {
                          onChange(hexadecimal);
                          dataConversationStore.updateDataConversation({
                            ...dataConversationStore.dataConversation,
                            hexadecimal,
                          });
                        }}
                      />
                    )}
                    name='hexadecimal'
                    rules={{ required: true }}
                    defaultValue=''
                  />

                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Form.Input
                        type='text'
                        label='Binary'
                        id='binary'
                        name='binary'
                        placeholder={
                          errors.binary ? 'Please Enter Binary' : 'Binary'
                        }
                        hasError={!!errors.binary}
                        value={value}
                        onChange={(binary) => {
                          onChange(binary);
                          dataConversationStore.updateDataConversation({
                            ...dataConversationStore.dataConversation,
                            binary,
                          });
                        }}
                      />
                    )}
                    name='binary'
                    rules={{ required: true }}
                    defaultValue=''
                  />
                </List>
                <List direction='col' space={4} justify='stretch' fill>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Form.Input
                        type='text'
                        label='ASCII'
                        id='ascii'
                        name='ascii'
                        placeholder={
                          errors.ascii ? 'Please Enter ascii' : 'ASCII'
                        }
                        hasError={!!errors.ascii}
                        value={value}
                        onChange={(ascii) => {
                          onChange(ascii);
                          dataConversationStore.updateDataConversation({
                            ...dataConversationStore.dataConversation,
                            ascii,
                          });
                        }}
                      />
                    )}
                    name='ascii'
                    rules={{ required: false }}
                    defaultValue=''
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Form.InputWrapper
                        label='Status'
                        hasError={!!errors.status}
                      >
                        <select
                          value={value}
                          className={`leading-4 p-2 focus:outline-none focus:ring block w-full shadow-sm sm:text-base border-2 ${
                            errors.status ? 'border-red  ' : 'border-gray-300'
                          } rounded-md`}
                          onChange={(e) => {
                            const status = e.target.value;
                            onChange(status);
                            dataConversationStore.updateDataConversation({
                              ...dataConversationStore.dataConversation,
                              status,
                            });
                          }}
                        >
                          <option>Select</option>
                          {lookupItems(routerStore.lookupItems, 'STATUS').map(
                            (item: any, index: number) => (
                              <option key={index} value={item.code}>
                                {lookupValue(item)}
                              </option>
                            ),
                          )}
                        </select>
                      </Form.InputWrapper>
                    )}
                    name='status'
                    rules={{ required: false }}
                    defaultValue=''
                  />
                </List>
              </Grid>
            ) : (
              <>
                {arrImportRecords?.length > 0 ? (
                  <StaticInputTable data={arrImportRecords} />
                ) : (
                  <ImportFile
                    onClick={(file) => {
                      handleFileUpload(file[0]);
                    }}
                  />
                )}
              </>
            )}
            <br />
            <List direction='row' space={3} align='center'>
              <Buttons.Button
                size='medium'
                type='solid'
                icon={Svg.Save}
                onClick={handleSubmit(onSubmitDataConversation)}
              >
                Save
              </Buttons.Button>

              <Buttons.Button
                size='medium'
                type='outline'
                icon={Svg.Remove}
                onClick={() => {
                  window.location.reload();
                }}
              >
                Clear
              </Buttons.Button>
              <div className='clearfix' />
            </List>
          </div>
          <div className='p-2 rounded-lg shadow-xl overflow-scroll'>
            <DataConversationList
              data={dataConversationStore.listdataConversation || []}
              extraData={{ lookupItems: routerStore.lookupItems }}
              totalSize={dataConversationStore.listdataConversationCount}
              isView={RouterFlow.checkPermission(
                routerStore.userPermission,
                'View',
              )}
              isDelete={RouterFlow.checkPermission(
                routerStore.userPermission,
                'Delete',
              )}
              isUpdate={RouterFlow.checkPermission(
                routerStore.userPermission,
                'Update',
              )}
              isExport={RouterFlow.checkPermission(
                routerStore.userPermission,
                'Export',
              )}
              onDelete={(selectedUser) => setModalConfirm(selectedUser)}
              onSelectedRow={(rows) => {
                setModalConfirm({
                  show: true,
                  type: 'Delete',
                  id: rows,
                  title: 'Are you sure?',
                  body: 'Do you want to delete selected record?',
                });
              }}
              onUpdateItem={(value: any, dataField: string, id: string) => {
                setModalConfirm({
                  show: true,
                  type: 'update',
                  data: { fields: { [dataField]: value }, id },
                  title: 'Are you sure?',
                  body: 'Do you want to update this record?',
                });
              }}
              onPageSizeChange={(page, limit) => {
                dataConversationStore.fetchDataConversation(page, limit);
                global.filter = { mode: 'pagination', page, limit };
              }}
              onFilter={(type, filter, page, limit) => {
                dataConversationStore.dataConversationService.filter({
                  input: { type, filter, page, limit },
                });
                global.filter = { mode: 'filter', type, filter, page, limit };
              }}
              onApproval={async (records) => {
                setModalConfirm({
                  show: true,
                  type: 'update',
                  data: { fields: { status: 'A' }, id: records._id },
                  title: 'Are you sure?',
                  body: 'Do you want to update this record?',
                });
              }}
            />
          </div>
          <ModalConfirm
            {...modalConfirm}
            click={(action?: string) => {
              if (action === 'Delete') {
                dataConversationStore.dataConversationService
                  .deleteDataConversation({ input: { id: modalConfirm.id } })
                  .then((res) => {
                    setModalConfirm({ show: false });
                    if (res.removeDataConversation.success) {
                      Toast.success({
                        message: `😊 ${res.removeDataConversation.message}`,
                      });
                      dataConversationStore.dataConversationService.listDataConversation();
                    }
                  });
              } else if (action == 'update') {
                dataConversationStore.dataConversationService
                  .update({
                    input: {
                      ...modalConfirm.data.fields,
                      _id: modalConfirm.data.id,
                    },
                  })
                  .then((res) => {
                    setModalConfirm({ show: false });
                    if (res.updateDataConversation.success) {
                      Toast.success({
                        message: `😊 ${res.updateDataConversation.message}`,
                      });
                      dataConversationStore.dataConversationService.listDataConversation();
                    }
                  });
              }
            }}
            close={() => setModalConfirm({ show: false })}
          />
        </div>
      </>
    );
  }),
);

export default DataConversation;
