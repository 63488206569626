import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  TableBootstrap,
  textFilter,
  Icons,
  Tooltip,
  sortCaret,
  customFilter,
  NumberFilter,
} from '@/library/components';
import { Confirm } from '@/library/models';
import { lookupItems, lookupValue } from '@/library/utils';
import { Form } from 'react-restyle-components';
import {
  AutocompleteLab,
  AutocompleteDepartment,
  AutocompleteInstType,
} from '..';

let lab;
let department;
let instType;
let instId;
let ipAddress;
let portNo;
let analyteCode;
let analyteName;
let testCode;
let testName;
let enteredBy;
let environment;
let companyCode;
let status;

interface InstrumentMasterListProps {
  data: any;
  extraData: any;
  totalSize: number;
  isView?: boolean;
  isDelete?: boolean;
  isUpdate?: boolean;
  isExport?: boolean;
  onDelete?: (selectedItem: Confirm) => void;
  onSelectedRow?: (selectedItem: any) => void;
  onUpdateItem?: (value: any, dataField: string, id: string) => void;
  onPageSizeChange?: (page: number, totalSize: number) => void;
  onFilter?: (
    type: string,
    filter: any,
    page: number,
    totalSize: number,
  ) => void;
  onApproval(row: any): void;
}

export const InstrumentMasterList = observer(
  (props: InstrumentMasterListProps) => {
    return (
      <>
        <div className={`${props.isView ? 'shown' : 'hidden'}`}>
          <TableBootstrap
            id='_id'
            data={props.data}
            totalSize={props.totalSize}
            columns={[
              {
                dataField: '_id',
                text: 'Id',
                hidden: true,
                csvExport: false,
              },
              {
                dataField: 'lab',
                text: 'PLab',
                sort: true,
                headerStyle: {
                  fontSize: 0,
                },
                headerClasses: 'textHeader',
                sortCaret: (order, column) => sortCaret(order, column),
                filter: textFilter({
                  placeholder: 'PLab',
                  getFilter: (filter) => {
                    lab = filter;
                  },
                }),
                editorRenderer: (
                  editorProps,
                  value,
                  row,
                  column,
                  rowIndex,
                  columnIndex,
                ) => (
                  <>
                    <AutocompleteLab
                      onSelect={(item) => {
                        props.onUpdateItem &&
                          props.onUpdateItem(
                            item.code,
                            column.dataField,
                            row._id,
                          );
                      }}
                    />
                  </>
                ),
              },
              {
                dataField: 'department',
                text: 'Department',
                sort: true,
                headerClasses: 'textHeader',
                headerStyle: {
                  fontSize: 0,
                },
                sortCaret: (order, column) => sortCaret(order, column),
                filter: textFilter({
                  placeholder: 'Department',
                  getFilter: (filter) => {
                    department = filter;
                  },
                }),
                editorRenderer: (
                  editorProps,
                  value,
                  row,
                  column,
                  rowIndex,
                  columnIndex,
                ) => (
                  <>
                    <AutocompleteDepartment
                      lab={row?.lab}
                      onSelect={(item) => {
                        props.onUpdateItem &&
                          props.onUpdateItem(
                            item.code,
                            column.dataField,
                            row._id,
                          );
                      }}
                    />
                  </>
                ),
              },
              {
                dataField: 'instType',
                text: 'Inst Type',
                sort: true,
                headerClasses: 'textHeader',
                headerStyle: {
                  fontSize: 0,
                },
                sortCaret: (order, column) => sortCaret(order, column),
                filter: textFilter({
                  placeholder: 'Inst Type',
                  getFilter: (filter) => {
                    instType = filter;
                  },
                }),
                editorRenderer: (
                  editorProps,
                  value,
                  row,
                  column,
                  rowIndex,
                  columnIndex,
                ) => (
                  <>
                    <AutocompleteInstType
                      onSelect={(item) => {
                        props.onUpdateItem &&
                          props.onUpdateItem(
                            item.instrumentType,
                            column.dataField,
                            row._id,
                          );
                      }}
                    />
                  </>
                ),
              },
              {
                dataField: 'instId',
                text: 'Inst Id',
                sort: true,
                headerClasses: 'textHeader',
                headerStyle: {
                  fontSize: 0,
                },
                sortCaret: (order, column) => sortCaret(order, column),
                filter: textFilter({
                  placeholder: 'Inst Id',
                  getFilter: (filter) => {
                    instId = filter;
                  },
                }),
              },
              {
                dataField: 'ipAddress',
                text: 'Ip Address',
                sort: true,
                headerClasses: 'textHeader',
                headerStyle: {
                  fontSize: 0,
                },
                sortCaret: (order, column) => sortCaret(order, column),
                filter: textFilter({
                  placeholder: 'Ip Address',
                  getFilter: (filter) => {
                    ipAddress = filter;
                  },
                }),
              },
              {
                dataField: 'portNo',
                text: 'Port No',
                sort: true,
                headerStyle: {
                  fontSize: 0,
                },
                filter: customFilter({
                  getFilter: (filter) => {
                    portNo = filter;
                  },
                }),
                filterRenderer: (onFilter, column) => (
                  <NumberFilter onFilter={onFilter} column={column} />
                ),
                editorRenderer: (
                  editorProps,
                  value,
                  row,
                  column,
                  rowIndex,
                  columnIndex,
                ) => (
                  <>
                    <Form.Input
                      placeholder={row?.portNo}
                      type='number'
                      onBlur={(portNo) => {
                        props.onUpdateItem &&
                          props.onUpdateItem(
                            Number.parseInt(portNo),
                            column.dataField,
                            row?._id,
                          );
                      }}
                    />
                  </>
                ),
              },
              {
                dataField: 'enteredBy',
                text: 'Entered By',
                sort: true,
                headerClasses: 'textHeader',
                headerStyle: {
                  fontSize: 0,
                },
                editable: false,
                sortCaret: (order, column) => sortCaret(order, column),
                filter: textFilter({
                  placeholder: 'Entered By',
                  getFilter: (filter) => {
                    enteredBy = filter;
                  },
                }),
              },
              {
                dataField: 'status',
                text: 'Status',
                sort: true,
                headerClasses: 'textHeader',
                headerStyle: {
                  fontSize: 0,
                },
                sortCaret: (order, column) => sortCaret(order, column),
                filter: textFilter({
                  placeholder: 'Status',
                  getFilter: (filter) => {
                    status = filter;
                  },
                }),
                editable: (content, row, rowIndex, columnIndex) =>
                  row.status == 'D' || row.status == 'I' ? false : true,
                editorRenderer: (
                  editorProps,
                  value,
                  row,
                  column,
                  rowIndex,
                  columnIndex,
                ) => (
                  <>
                    <select
                      value={row.status}
                      className={
                        'leading-4 p-2 focus:outline-none focus:ring block w-full shadow-sm sm:text-base border-2 rounded-md'
                      }
                      onChange={(e) => {
                        const status = e.target.value;
                        props.onUpdateItem &&
                          props.onUpdateItem(status, column.dataField, row._id);
                      }}
                    >
                      <option>Select</option>
                      {lookupItems(props.extraData.lookupItems, 'STATUS')
                        .filter((item) => item.code != 'D')
                        .map((item: any, index: number) => (
                          <option key={index} value={item.code}>
                            {lookupValue(item)}
                          </option>
                        ))}
                    </select>
                  </>
                ),
              },
              {
                text: 'Company Code',
                dataField: 'companyCode',
                sort: true,
                headerStyle: {
                  fontSize: 0,
                },
                sortCaret: (order, column) => sortCaret(order, column),
                editable: false,
                filter: textFilter({
                  placeholder: 'Company Code',
                  getFilter: (filter) => {
                    companyCode = filter;
                  },
                }),
                headerClasses: 'textHeader2',
              },

              {
                dataField: 'environment',
                text: 'Environment',
                headerClasses: 'textHeader3',
                sort: true,
                headerStyle: {
                  fontSize: 0,
                },
                editable: false,
                sortCaret: (order, column) => sortCaret(order, column),
                filter: textFilter({
                  placeholder: 'Environment',
                  getFilter: (filter) => {
                    environment = filter;
                  },
                }),
              },
              {
                dataField: 'operation',
                text: 'Action',
                editable: false,
                csvExport: false,
                // hidden: !props.isDelete,
                formatter: (cellContent, row) => (
                  <>
                    <div className='flex flex-row'>
                      {props.isDelete && (
                        <Tooltip tooltipText='Delete'>
                          <Icons.IconContext
                            color='#ffffff'
                            size='20'
                            onClick={() =>
                              props.onDelete &&
                              props.onDelete({
                                type: 'delete',
                                show: true,
                                id: [row._id],
                                title: 'Are you sure?',
                                body: 'Do you want to delete this record?',
                              })
                            }
                          >
                            {Icons.getIconTag(Icons.IconBs.BsFillTrashFill)}
                          </Icons.IconContext>
                        </Tooltip>
                      )}
                      {row.status == 'D' && (
                        <Tooltip tooltipText='Approval'>
                          <Icons.RIcon
                            nameIcon='AiOutlineCheckCircle'
                            propsIcon={{ size: 24, color: '#ffffff' }}
                            onClick={() => props.onApproval(row)}
                          />
                        </Tooltip>
                      )}
                    </div>
                  </>
                ),
                headerClasses: 'sticky right-0  bg-gray-500 text-white z-50',
                classes: (cell, row, rowIndex, colIndex) => {
                  return 'sticky right-0 bg-gray-500';
                },
                style: (cell, row, rowIndex, colIndex) => {
                  return {
                    zIndex: props.data?.length - rowIndex,
                  };
                },
              },
            ]}
            isDelete={props.isDelete}
            isEditModify={props.isUpdate}
            isExport={props.isExport}
            isSelectRow={true}
            fileName='Instrument Master'
            onSelectedRow={(rows) => {
              props.onSelectedRow &&
                props.onSelectedRow(rows.map((item: any) => item._id));
            }}
            onUpdateItem={(value: any, dataField: string, id: string) => {
              props.onUpdateItem && props.onUpdateItem(value, dataField, id);
            }}
            onPageSizeChange={(page, size) => {
              props.onPageSizeChange && props.onPageSizeChange(page, size);
            }}
            onFilter={(type, filter, page, size) => {
              props.onFilter && props.onFilter(type, filter, page, size);
            }}
            clearAllFilter={() => {
              environment('');
              status('');
            }}
            dynamicStylingFields={['lab', 'environment']}
            hideExcelSheet={['_id', 'operation']}
          />
        </div>
      </>
    );
  },
);
