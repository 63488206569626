export {};
import React from 'react';
import { PdfFooterView, PdfImage, PdfRegular, PdfSmall } from '@components';
import { logos } from '@/library/assets';
// import { getBarCode, getQRCode } from '@/core-utils';

interface FooterProps {
  data?: any;
}

interface FooterBillingProps {
  data?: any;
}

export const DemoFooter = ({ data }: FooterProps) => {
  return (
    <PdfFooterView fixed bg='#ffffff' left={2} right={2} p={0}>
      <PdfImage
        src={logos.demoFooter}
        style={{
          width: '100%',
        }}
      />
      {/* {data?.barCode && (
        <PdfImage
          src={getQRCode(data?.barCode)}
          style={{
            position: 'absolute',
            right: 10,
            bottom: 50,
            height: 40,
          }}
        />
      )} */}
    </PdfFooterView>
  );
};

export const DemoFooterBilling = ({ data }: FooterBillingProps) => {
  return (
    <PdfFooterView fixed bg='#ffffff' left={2} right={2} p={0}>
      <PdfImage
        src={logos.demoFooter}
        style={{
          width: '100%',
        }}
      />
    </PdfFooterView>
  );
};

export const GeneflowLabFooter = ({ data }: FooterProps) => {
  return (
    <PdfFooterView fixed bg='#ffffff' left={2} right={2} p={0}>
      <PdfImage
        src={logos.geneflowFooter}
        style={{
          width: '100%',
        }}
      />
      {/* {data?.barCode && (
        <PdfImage
          src={getQRCode(data?.barCode)}
          style={{
            position: 'absolute',
            right: 10,
            bottom: 50,
            height: 40,
          }}
        />
      )} */}
    </PdfFooterView>
  );
};

export const GeneflowLabFooterBilling = ({ data }: FooterBillingProps) => {
  return (
    <PdfFooterView fixed bg='#ffffff' left={2} right={2} p={0}>
      <PdfImage
        src={logos.geneflowFooterBilling}
        style={{
          width: '100%',
        }}
      />
    </PdfFooterView>
  );
};

export const AarvakDiagnosticCenterFooter = ({ data }: FooterProps) => {
  return (
    <PdfFooterView fixed bg='white' p={5}>
      <PdfSmall>
        <PdfRegular style={{ color: '#A52728' }}>Red. Address:</PdfRegular>{' '}
        1310, Behind SBI Bank, Badshahpur, Sohna Road, Sector 66, Gurugram,
        Haryana - 122101,
      </PdfSmall>
      {/* <PdfSmall>
        <PdfRegular style={{color: '#A52728'}}>Lab Address:</PdfRegular>Opp.
        Radha Krishna Mandir, Main Sohna Road, Badhshahpur, Sector 66, Gurugram,
        Haryana - 122101
      </PdfSmall> */}
      <PdfSmall>
        <PdfRegular style={{ color: '#A52728' }}>Phone:</PdfRegular> +91
        9810063340, 9354212163{' '}
        <PdfRegular style={{ color: '#A52728' }}>Email:</PdfRegular>{' '}
        info@aarvakdiagnosticcentre.com
      </PdfSmall>
      <PdfRegular style={{ color: '#A52728' }}>
        www.aarvakdiagnosticcentre.com
      </PdfRegular>
    </PdfFooterView>
  );
};

export const AccustixFooter = ({ data }: FooterProps) => {
  return (
    <PdfFooterView fixed bg='#ffffff' left={2} right={2} p={0}>
      <PdfImage
        src={logos.accustixFooter}
        style={{
          width: '100%',
          bottom: 0,
          position: 'absolute',
        }}
      />
    </PdfFooterView>
  );
};

export const AccustixFooterBilling = ({ data }: FooterBillingProps) => {
  return (
    <PdfFooterView fixed bg='#ffffff' left={2} right={2} p={0}>
      <PdfImage
        src={logos.accustixFooter}
        style={{
          width: '100%',
          bottom: 0,
          position: 'absolute',
        }}
      />
    </PdfFooterView>
  );
};
