import React from 'react';
import { PdfHeader, PdfView, PdfImage, PdfMedium, PdfSmall } from '@components';
import { logos } from '@/library/assets';
import { getBarCode } from '@/core-utils';

interface HeaderProps {
  data?: any;
}

interface HeaderBillingProps {
  data?: any;
}

export const DemoHeader = ({ data }: HeaderProps) => {
  return (
    <>
      <PdfHeader
        p={0}
        bg='white'
        fixed
        style={{ marginLeft: 4, marginBottom: 4 }}
      >
        <PdfImage
          src={logos.demoHeader}
          style={{
            width: '100%',
          }}
        />
      </PdfHeader>
      {data?.labId && (
        <PdfImage
          src={getBarCode(data?.labId)}
          style={{
            position: 'absolute',
            right: 36,
            bottom: -4,
            height: 40,
          }}
        />
      )}
    </>
  );
};

export const DemoHeaderBilling = ({ data }: HeaderBillingProps) => {
  return (
    <>
      <PdfHeader
        p={0}
        bg='white'
        fixed
        style={{ marginLeft: 4, marginTop: 2, marginBottom: 4 }}
      >
        <PdfImage
          src={logos.demoHeader}
          style={{
            width: '100%',
          }}
        />
      </PdfHeader>
      {data?.labId && (
        <PdfImage
          src={getBarCode(data?.labId)}
          style={{
            position: 'absolute',
            right: 28,
            bottom: -20,
            height: 40,
          }}
        />
      )}
    </>
  );
};

export const GeneflowLabHeader = ({ data }: HeaderProps) => {
  return (
    <>
      <PdfHeader
        p={0}
        bg='white'
        fixed
        style={{ marginLeft: 4, marginBottom: 4 }}
      >
        <PdfImage
          src={logos.geneflowHeader}
          style={{
            width: '100%',
          }}
        />
      </PdfHeader>
      {data?.labId && (
        <PdfImage
          src={getBarCode(data?.labId)}
          style={{
            position: 'absolute',
            right: 36,
            bottom: -4,
            height: 40,
          }}
        />
      )}
    </>
  );
};

export const GeneflowLabHeaderBilling = ({ data }: HeaderBillingProps) => {
  return (
    <>
      <PdfHeader
        p={0}
        bg='white'
        fixed
        style={{ marginLeft: 4, marginTop: 2, marginBottom: 4 }}
      >
        <PdfImage
          src={logos.geneflowHeaderBilling}
          style={{
            width: '100%',
          }}
        />
      </PdfHeader>
      {data?.labId && (
        <PdfImage
          src={getBarCode(data?.labId)}
          style={{
            position: 'absolute',
            right: 28,
            bottom: -20,
            height: 40,
          }}
        />
      )}
    </>
  );
};

export const AarvakDiagnosticCenterHeader = ({ data }: HeaderProps) => {
  return (
    <PdfHeader
      p={0}
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      bg='white'
      fixed
    >
      <PdfImage
        src={logos.aarvakDiagnosticCenter}
        style={{
          width: 100,
          height: 100,
          marginHorizontal: 10,
        }}
      />
      <PdfView mh={0} p={0}>
        <PdfMedium fontSize={14} style={{ color: 'green' }}>
          Committed to provide
        </PdfMedium>
        <PdfMedium style={{ marginTop: -4 }}>
          <PdfMedium style={{ color: '#A52728' }}>Best Diagnostic </PdfMedium>
          Services<PdfMedium style={{ color: 'green' }}>+</PdfMedium>
        </PdfMedium>
      </PdfView>
    </PdfHeader>
  );
};

export const AccustixHeader = ({ data }: HeaderProps) => {
  return (
    <>
      <PdfHeader
        p={0}
        bg='white'
        fixed
        style={{ marginLeft: 4, marginBottom: 4 }}
      >
        <PdfImage
          src={logos.accustixHeader}
          style={{
            width: '100%',
          }}
        />
      </PdfHeader>
    </>
  );
};

export const AccustixHeaderBilling = ({ data }: HeaderBillingProps) => {
  return (
    <>
      <PdfHeader
        p={0}
        bg='white'
        fixed
        style={{ marginLeft: 4, marginTop: 2, marginBottom: 4 }}
      >
        <PdfImage
          src={logos.accustixHeader}
          style={{
            width: '100%',
          }}
        />
      </PdfHeader>
    </>
  );
};
