import React, { useRef } from 'react';
import {
  AarvakDiagnosticCenterHeader,
  AarvakDiagnosticCenterFooter,
  DemoHeader,
  DemoFooter,
  DemoHeaderBilling,
  DemoFooterBilling,
  GeneflowLabHeader,
  GeneflowLabHeaderBilling,
  GeneflowLabFooter,
  GeneflowLabFooterBilling,
  AccustixHeader,
  AccustixFooter,
  AccustixHeaderBilling,
  AccustixFooterBilling,
} from '@/features/report-builder/report-template/components/molecules/pdf/company';
import JsBarcode from 'jsbarcode';
import QRCode from 'qrcode';
import { Document } from '@react-pdf/renderer';
import {
  PdfTemp0001,
  PdfTemp0002,
  PdfTemp0003,
  PdfTemp0004,
  PdfTemp0005,
  PdfTemp0006,
  PdfTemp0007,
  PdfTemp0008,
  PdfTemp0009,
  PdfTemp0010,
} from '@/features/report-builder/report-template/components';
import { stores } from '@/stores';
import _ from 'lodash';

export const getHeaderAndFooter = (companyCode, details) => {
  switch (companyCode) {
    case 'DEMO':
      return {
        header: <DemoHeader data={details} />,
        footer: <DemoFooter data={details} />,
      };
    case 'GENEFLOW':
      return {
        header: <GeneflowLabHeader data={details} />,
        footer: <GeneflowLabFooter data={details} />,
      };
    case 'COMP0001':
      return {
        header: <AarvakDiagnosticCenterHeader />,
        footer: <AarvakDiagnosticCenterFooter />,
      };
    case 'ACCUSTIX':
      return {
        header: <AccustixHeader />,
        footer: <AccustixFooter />,
      };
    default:
      break;
  }
};

export const getHeaderAndFooterBilling = (companyCode, details) => {
  switch (companyCode) {
    case 'DEMO':
      return {
        header: <DemoHeaderBilling data={details} />,
        footer: <DemoFooterBilling />,
      };
    case 'GENEFLOW':
      return {
        header: <GeneflowLabHeaderBilling data={details} />,
        footer: <GeneflowLabFooterBilling />,
      };
    case 'COMP0001':
      return {
        header: <AarvakDiagnosticCenterHeader />,
        footer: <AarvakDiagnosticCenterFooter />,
      };
    case 'ACCUSTIX':
      return {
        header: <AccustixHeaderBilling />,
        footer: <AccustixFooterBilling />,
      };
    default:
      break;
  }
};

export const getBarCode = (variable: any = 'https://www.limsplus.co.in') => {
  let canvas: any = '';
  canvas = document.createElement('canvas');
  JsBarcode(canvas, variable?.toString(), {
    lineColor: '#000',
    displayValue: true,
    height: 30,
  });
  return canvas.toDataURL();
};

export const getQRCode = (variable: any) => {
  let canvas: any = '';
  canvas = document.createElement('canvas');
  QRCode.toCanvas(canvas, variable?.toString());
  return canvas.toDataURL();
};

export const getReports = (
  reports,
  templateDetails,
  isWithHeader: boolean = true,
  isEmptyHeaderFooterSpace = false,
  companyCode = stores.loginStore.login.companyCode,
) => {
  const documentTitle = 'Delivery Queue';
  return (
    <Document title={documentTitle}>
      {reports?.map((item) => (
        <>
          {item.template == 'TEMP0001' &&
            _.uniqBy(item?.patientReports, 'labId')?.map(
              (patientReports: any, i: number) => (
                <PdfTemp0001
                  key={i}
                  data={{
                    patientReports: {
                      ...patientReports,
                      patientResultList:
                        patientReports?.patientResultList?.filter(
                          (item) =>
                            item?.reportTemplate?.split(' -')[0] == 'TEMP0001',
                        ),
                    },
                    pageBranding: templateDetails?.find(
                      (item) => item.templateCode == 'TEMP0001',
                    ),
                  }}
                />
              ),
            )}

          {item.template == 'TEMP0002' &&
            _.uniqBy(item?.patientReports, 'labId')?.map(
              (patientReports: any, i: number) => (
                <PdfTemp0002
                  key={i}
                  data={{
                    patientReports: {
                      ...patientReports,
                      patientResultList:
                        patientReports?.patientResultList?.filter(
                          (item) =>
                            item?.reportTemplate?.split(' -')[0] == 'TEMP0002',
                        ),
                    },
                    pageBranding: templateDetails?.find(
                      (item) => item.templateCode == 'TEMP0002',
                    ),
                  }}
                />
              ),
            )}
          {item.template == 'TEMP0003' &&
            _.uniqBy(item?.patientReports, 'labId')?.map(
              (patientReports: any, i: number) => (
                <PdfTemp0003
                  key={i}
                  data={{
                    patientReports: {
                      ...patientReports,
                      patientResultList:
                        patientReports?.patientResultList?.filter(
                          (item) =>
                            item?.reportTemplate?.split(' -')[0] == 'TEMP0003',
                        ),
                    },
                    pageBranding: templateDetails?.find(
                      (item) => item.templateCode == 'TEMP0003',
                    ),
                  }}
                />
              ),
            )}
          {item.template == 'TEMP0004' &&
            _.uniqBy(item?.patientReports, 'labId')?.map(
              (patientReports: any, i: number) => (
                <PdfTemp0004
                  key={i}
                  data={{
                    patientReports: {
                      ...patientReports,
                      patientResultList:
                        patientReports?.patientResultList?.filter(
                          (item) =>
                            item?.reportTemplate?.split(' -')[0] == 'TEMP0004',
                        ),
                    },
                    pageBranding: templateDetails?.find(
                      (item) => item?.templateCode == 'TEMP0004',
                    ),
                  }}
                  isWithHeader={isWithHeader}
                  companyCode={companyCode}
                />
              ),
            )}
          {item.template == 'TEMP0005' &&
            _.uniqBy(item?.patientReports, 'labId')?.map(
              (patientReports: any, i: number) => (
                <PdfTemp0005
                  key={i}
                  data={{
                    patientReports: {
                      ...patientReports,
                      patientResultList:
                        patientReports?.patientResultList?.filter(
                          (item) =>
                            item?.reportTemplate?.split(' -')[0] == 'TEMP0005',
                        ),
                    },
                    pageBranding: templateDetails?.find(
                      (item) => item.templateCode == 'TEMP0005',
                    ),
                  }}
                  isWithHeader={isWithHeader}
                />
              ),
            )}
          {item.template == 'TEMP0006' &&
            _.uniqBy(item?.patientReports, 'labId')?.map(
              (patientReports: any, i: number) => (
                <PdfTemp0006
                  key={i}
                  data={{
                    patientReports: {
                      ...patientReports,
                      patientResultList:
                        patientReports?.patientResultList?.filter(
                          (item) =>
                            item?.reportTemplate?.split(' -')[0] == 'TEMP0006',
                        ),
                    },
                    pageBranding: templateDetails?.find(
                      (item) => item.templateCode == 'TEMP0006',
                    ),
                  }}
                  isWithHeader={isWithHeader}
                />
              ),
            )}
          {item.template == 'TEMP0007' &&
            _.uniqBy(item?.patientReports, 'labId')?.map(
              (patientReports: any, i: number) => (
                <PdfTemp0007
                  key={i}
                  data={{
                    patientReports: {
                      ...patientReports,
                      patientResultList:
                        patientReports?.patientResultList?.filter(
                          (item) =>
                            item?.reportTemplate?.split(' -')[0] == 'TEMP0007',
                        ),
                    },
                    pageBranding: templateDetails?.find(
                      (item) => item.templateCode == 'TEMP0007',
                    ),
                  }}
                />
              ),
            )}
          {item.template == 'TEMP0008' &&
            _.uniqBy(item?.patientReports, 'labId')?.map(
              (patientReports: any, i: number) => (
                <PdfTemp0008
                  key={i}
                  data={{
                    patientReports: {
                      ...patientReports,
                      patientResultList:
                        patientReports?.patientResultList?.filter(
                          (item) =>
                            item?.reportTemplate?.split(' -')[0] == 'TEMP0008',
                        ),
                    },
                    pageBranding: templateDetails?.find(
                      (item) => item.templateCode == 'TEMP0008',
                    ),
                  }}
                  companyCode={companyCode}
                />
              ),
            )}
          {item.template == 'TEMP0009' &&
            _.uniqBy(item?.patientReports, 'labId')?.map(
              (patientReports: any, i: number) => (
                <PdfTemp0009
                  key={i}
                  data={{
                    patientReports: {
                      ...patientReports,
                      patientResultList:
                        patientReports?.patientResultList?.filter(
                          (item) =>
                            item?.reportTemplate?.split(' -')[0] == 'TEMP0009',
                        ),
                    },
                    pageBranding: templateDetails?.find(
                      (item) => item?.templateCode == 'TEMP0009',
                    ),
                  }}
                  isWithHeader={isWithHeader}
                  companyCode={companyCode}
                />
              ),
            )}
          {item.template == 'TEMP0010' &&
            _.uniqBy(item?.patientReports, 'labId')?.map(
              (patientReports: any, i: number) => (
                <PdfTemp0010
                  data={{
                    patientReports: {
                      ...patientReports,
                      patientResultList:
                        patientReports?.patientResultList?.filter(
                          (item) =>
                            item?.reportTemplate?.split(' -')[0] == 'TEMP0010',
                        ),
                    },
                    pageBranding: templateDetails?.find(
                      (item) => item?.templateCode == 'TEMP0010',
                    ),
                  }}
                  isWithHeader={isWithHeader}
                  companyCode={companyCode}
                />
              ),
            )}
        </>
      ))}
    </Document>
  );
};

export const reportList = (data) => {
  if (data) {
    const newObj = _.mapKeys(data, (value, key) => key.split(' -')[0]);
    let arrReportList: any = [];
    for (const values of Object.entries(newObj)) {
      let templateReportOrder = 0;
      values[1].map((item) =>
        item?.patientResultList?.map((e) => {
          if (e.reportTemplate?.split(' - ')[0] == values[0]) {
            templateReportOrder = e.reportTemplateOrder;
          }
        }),
      );
      arrReportList.push({
        template: values[0],
        patientReports: values[1],
        order: templateReportOrder,
      });
    }
    arrReportList = _.orderBy(arrReportList, 'order', 'asc');
    return arrReportList;
  }
};

// html viewer
export const regex = /style=(.*)font-[^;]+;/g;
export const subst = '';

export const pdfStylesheet = {
  body: {
    fontSize: '10px',
    fontFamily: 'ArimaRegular',
    margin: 0,
    padding: 0,
  },
  p: {
    margin: 0,
    fontSize: '10px',
  },
  table: {
    border: '1px solid !important',
    marginTop: 4,
    marginBottom: 4,
  },
  td: {
    padding: 2,
  },
  strong: {
    fontFamily: 'IBMPlexSans',
    fontWeight: 'bold',
  },
  em: {
    fontFamily: 'IBMPlexSans',
    fontStyle: 'italic',
  },
  sup: {
    verticalAlign: 'super',
    fontSize: '8px',
  },
  sub: {
    verticalAlign: 'sub',
    fontSize: '8px',
  },
};

export const html = (content) => `
<html>
<head>
</head>
<style>
.boldClass {
  font-weight: bold;
  color: black;
}
</style>
  <body>
      ${content}
  </body>
</html>
`;
export const htmlContent = (details: string) => {
  const container = document.createElement('div');
  container.innerHTML = details;
  const tables = container.querySelectorAll('table');
  tables.forEach((items, index) => {
    const width = items.style.width;
    const height = items.style.height;
    if (width?.includes('px') || height?.includes('px')) {
      items.style.width = '100%';
      items.style.height = 'auto';
    }
    items.innerHTML = items.innerHTML?.replaceAll('width', 'maxWidth');
    // for bold
    // items.innerHTML = items.innerHTML?.replaceAll('strong', 'maxWidth');
    const row = items.querySelectorAll('tr');
    const tdWidth = row[0]?.querySelectorAll('td');
    row.forEach((tr, i) => {
      if (i > 0) {
        tr.querySelectorAll('td')?.forEach((td, tdi) => {
          td.setAttribute('style', tdWidth[tdi]?.getAttribute('style') as any);
        });
      }
    });
  });
  return container.innerHTML;
};
