import { AutoCompleteFilterSingleSelectMultiFieldsDisplay } from 'react-restyle-components';
import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@/stores';

interface AutocompleteDepartmentProps {
  lab: string;
  onSelect: (item: any) => void;
}

export const AutocompleteDepartment = observer(
  ({ lab, onSelect }: AutocompleteDepartmentProps) => {
    const { departmentStore } = useStores();
    return (
      <div>
        <AutoCompleteFilterSingleSelectMultiFieldsDisplay
          placeholder='Search by code or name'
          data={{
            list: departmentStore?.listDepartment.filter(
              (item) => item.lab === lab,
            ),
            displayKey: ['code', 'name'],
          }}
          onFilter={(value: string) => {
            departmentStore.DepartmentService.filterByFields({
              input: {
                filter: {
                  fields: ['code', 'name'],
                  srText: value,
                },
                page: 0,
                limit: 10,
              },
            });
          }}
          onSelect={(item) => {
            onSelect(item);
            departmentStore.updateDepartmentList(
              departmentStore.listDepartmentCopy,
            );
          }}
        />
      </div>
    );
  },
);
