import { AutoCompleteFilterSingleSelect } from 'react-restyle-components';
import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@/stores';

interface AutocompleteLabProps {
  onSelect: (item: any) => void;
}

export const AutocompleteLab = observer(
  ({ onSelect }: AutocompleteLabProps) => {
    const { labStore } = useStores();
    return (
      <div>
        <AutoCompleteFilterSingleSelect
          placeholder='Search...'
          data={{
            list: labStore.listLabs?.filter((item) => item.status == 'A'),
            displayKey: 'name',
            findKey: 'name',
          }}
          onFilter={(value: string) => {
            labStore.LabService.filter({
              input: {
                type: 'filter',
                filter: {
                  name: value,
                },
                page: 0,
                limit: 10,
              },
            });
          }}
          onSelect={(item) => {
            onSelect(item);
          }}
        />
      </div>
    );
  },
);
