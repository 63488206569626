import { AutoCompleteFilterSingleSelect } from 'react-restyle-components';
import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@/stores';

interface AutocompleteInstTypeProps {
  onSelect: (item: any) => void;
}

export const AutocompleteInstType = observer(
  ({ onSelect }: AutocompleteInstTypeProps) => {
    const { loginStore, interfaceManagerStore } = useStores();
    return (
      <div>
        <AutoCompleteFilterSingleSelect
          placeholder='Search...'
          disable={
            loginStore.login && loginStore.login.role !== 'ADMINISTRATOR'
              ? true
              : false
          }
          data={{
            list: interfaceManagerStore.listInterfaceManager.filter(
              (item) => item.interfaceType === 'INSTRUMENT',
            ),
            displayKey: 'instrumentType',
            findKey: 'instrumentType',
          }}
          onFilter={(value: string) => {
            interfaceManagerStore.interfaceManagerService.filter({
              input: {
                type: 'filter',
                filter: {
                  instrumentType: value,
                },
                page: 0,
                limit: 10,
              },
            });
          }}
          onSelect={(item) => {
            onSelect(item);
          }}
        />
      </div>
    );
  },
);
